<template>
  <v-dialog :value="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="text-h5 text-center">
        Send Payment Link
      </v-card-title>

      <div class="px-5 my-5">
        <v-text-field
          v-model="phone"
          dense
          outlined
          label="Phone"
          class="mb-3"
          :rules="[rules.required]"
        >
        </v-text-field>
        <v-textarea
          name="input-7-1"
          :label="messageLabel"
          v-model="message"
          dense
          outlined
          :rules="[rules.required, rules.max]"
        ></v-textarea>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')" :disabled="sendingReminder">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="sendInvoiceReminder"
          :disabled="sendingReminder"
          :loading="sendingReminder"
        >
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions: invoiceActions, mapGetters: invoiceGetter } =
  createNamespacedHelpers('invoices');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapGetters: patientGetters } = createNamespacedHelpers('patient');

export default {
  name: 'EditReminder',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: '',
      phone: '',
      rules: {
        required: (value) => !!value || 'Required.',
        max: (v) => v.length <= 160 || 'Max message length is 160 characters',
      },
    };
  },
  watch: {
    dialog() {
      this.message = '';
      this.phone = '';
      if (this.dialog) {
        let template = this.customer.CustomersFeature?.smsTemplateDropIn ?? '';
        const host = process.env.VUE_APP_WEB_APP_URL;
        const link = `${host}/?code={short_code}&type=billing`;
        template = template?.replace('{link}', link);
        template = template?.replace('{short_code}', this.patient?.short_code);

        template = template?.replace('{firstname}', this.patient.firstname);

        this.message = template;
        this.phone = this.patient?.cellphone;
      }
    },
  },
  computed: {
    ...patientGetters({
      patient: 'PATIENT',
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
    }),
    ...invoiceGetter({
      sendingReminder: 'SEND_REMINDER_LOADING',
    }),
    messageLabel() {
      const count = this.message?.length ?? 0;
      return `Message (${count}/160)`;
    },
    showErrorMessage() {
      return this.message.length > 160;
    },
  },
  methods: {
    ...invoiceActions(['sendReminder']),
    async sendInvoiceReminder() {
      if (this.message.length > 160 || !this.phone) {
        return;
      }

      const resp = await this.sendReminder({
        phone: this.phone,
        message: this.message,
      });
      if (resp.success) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './edit-reminder';
</style>
