<template>
  <div>
    <button
      type="button"
      class="btn btn-block btn-success no-border payment-btn"
      :class="{ 'is-loading': sendBalanceTransactionLoading }"
      @click="pay">
      {{ payButtonText() }}
    </button>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { helpers } from '@/mixins/helpers';

const { mapGetters: invoiceGetters, mapActions: invoiceActions } =
  createNamespacedHelpers('invoices');

export default {
  name: 'pay-button',
  data() {
    return {
      loading: false,
      message: {
        title: 'Payment was not processed',
        text: 'Transaction failed. Please try again later',
      },
    };
  },
  props: {
    paymentData: {
      type: Object,
      required: true,
    },
    paymentType: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...invoiceGetters({
      sendBalanceTransactionLoading: 'SEND_BALANCE_TRANSACTION_LOADING',
    }),
  },
  mixins: [helpers],
  methods: {
    ...invoiceActions(['sendBalanceTransactions']),
    payButtonText() {
      return `Pay $${this.priceFormat(this.paymentData.amount)}`;
    },
    async pay() {
      if (!this.paymentData.amount) return;
      if (this.sendBalanceTransactionLoading) return;

      const error = await this.sendBalanceTransactions(this.paymentData);
      this.$emit('setModalResult', error);
      this.$emit('updateDetails');
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/variables.scss';
@import './pay-button.scss';
</style>
