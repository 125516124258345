<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="900"
    :value="dialog"
    @click:outside="close()"
    scrollable
    content-class="modal__container pay-modal"
  >
    <!-- Success Message -->
    <div class="modal__body" v-if="success">
      <div class="payment-result">
        <v-icon class="payment-result__icon" color="success">
          mdi-check-circle
        </v-icon>
        <h2>Payment processed successfully</h2>
      </div>
    </div>

    <!-- Error Message -->
    <div class="modal__body" v-else-if="error">
      <div class="payment-result">
        <v-icon class="payment-result__icon" color="error">
          mdi-alert-circle
        </v-icon>
        <h4>Error occurred while making a payment.</h4>
        <p>
          {{ errorMessage ? 'Message: ' + errorMessage : '' }}
        </p>
      </div>
    </div>

    <!-- Default Body -->
    <div class="modal__body" v-else>
      <template v-if="optionTitle">
        <h3 class="checkout-option__title">E-Payment option</h3>
        <section class="payment-options">
          <div class="payment-option">
            <div class="payment-option__title">
              {{ optionTitle }}
            </div>
          </div>
        </section>

        <h3 class="checkout-option__title">E-Payment method</h3>
        <div
          class="card-number"
          @click="openCardModal"
          :class="{ empty: isEmptyCard, 'not-empty': !isEmptyCard }"
        >
          <div class="card-number__logo" :class="{ 'is-empty': isEmptyCard }">
            <template v-if="!isEmptyCard">
              <img
                :src="
                  require(`@/assets/images/card-types/${card.type}-dark.png`)
                "
                alt=""
              />
            </template>
            <template v-else>
              <img src="@/assets/images/card-types/empty.png" alt="" />
            </template>
          </div>

          <div class="card-number__title">
            <template v-if="!isEmptyCard">
              xxxx-xxxx-xxxx-{{ card?.lastfour }}
            </template>
            <template v-else>
              <div>Add card</div>
            </template>
          </div>

          <div class="card-number__date" v-if="!isEmptyCard">
            {{ card?.date }}
          </div>
        </div>

        <div v-if="card?.id">
          <div class="payment-method__pay">
            <pay-button
              :paymentData="paymentData"
              :paymentType="paymentType"
              @setModalResult="setModalResult"
              @updateDetails="close"
            ></pay-button>
            <div
              class="payment-method__information payment-method__information_security"
            >
              Payment information is TLS encrypted with a PCI Level I compliant
              processor
            </div>
          </div>
        </div>
      </template>
    </div>

    <manage-cards
      v-if="patient?.id && showManageCardModal"
      :pid="patient?.id"
      :showManageCardModal="showManageCardModal"
      @closeModal="showManageCardModal = false"
    ></manage-cards>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import payButton from './pay-button/pay-button.vue';
import ManageCards from '@/components/manage-cards/manage-cards.vue';

const { mapGetters: patientGetters } = createNamespacedHelpers('patient');
const { mapGetters: invoiceGetters } = createNamespacedHelpers('invoices');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'payModal',
  components: {
    payButton,
    ManageCards,
  },
  props: {
    paymentType: {
      required: true,
    },
    paymentData: {
      required: true,
    },
    optionTitle: {
      required: true,
    },
    toggleDialog: {
      type: Function,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      success: false,
      error: false,
      errorMessage: null,
      showManageCardModal: false,
    };
  },
  computed: {
    ...invoiceGetters({
      cards: 'CARDS',
      invoiceDetails: 'INVOICE_DETAILS',
    }),
    ...patientGetters({
      patient: 'PATIENT',
    }),
    ...customerGetters({
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    card() {
      if (!this.cards.length) return null;
      return this.cards.find((c) => c.is_primary);
    },
    isEmptyCard() {
      return this.card ? false : true;
    },
  },
  methods: {
    setModalResult(error) {
      if (error) {
        this.success = false;
        this.error = true;
        this.errorMessage = error?.message;
      } else {
        this.success = true;
        this.errorMessage = null;
      }
    },
    close() {
      this.error = false;
      this.success = false;
      this.errorMessage = null;
      this.toggleDialog();
      this.$emit('updateDetails');
    },
    openCardModal() {
      this.showManageCardModal = true;
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/variables.scss';

.modal {
  position: absolute;
  left: 50%;
  top: 150px;
  margin-left: -150px;
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &__container {
    border-radius: 8px;
    padding: 30px;
    background-color: #fff;
    width: 400px;
    min-height: 94px;
  }
}

.pay-modal {
  .checkout-option {
    &__title {
      text-align: left;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 10px;
      color: #9b9b9b;
    }
  }
  .card-number {
    $root: &;

    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 40px;
    // color: #9B9B9B;
    &__logo {
      width: 60px;
      text-align: center;
      img {
        max-width: 40px;
        vertical-align: middle;
      }
    }
    &__title {
      margin: 0 15px;
      text-align: left;
    }
    &__date {
      font-size: 14px;
      width: 60px;
      text-align: center;
    }

    &__dot {
      display: block;
      width: 5px;
      height: 5px;
      margin: 2px;
      background-color: $c_grey_1;
      border-radius: 50%;
    }
    &__actions {
      display: flex;
      align-items: center;
      line-height: 1;
    }
    &__delete {
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid currentColor;
      opacity: 0.75;
      transition: 0.3s all;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    &__preloader {
      .preloader {
        width: 24px;
        height: 24px;
      }
    }
    /** Mods: */
    &.empty {
      cursor: pointer;
    }
    &.not-empty {
      padding-left: 5px;
      box-shadow: none;
      #{$root}__title {
        font-size: 14px;
      }
    }
  }
  .payment-options {
    margin-bottom: 10px;
    .title {
      text-align: left;
      margin-bottom: 10px;
      margin-top: 10px;
      text-transform: uppercase;
      font-size: 18px;
      color: $c_grey;
    }
  }
  .payment-option {
    min-height: 52px;
    border-radius: 8px;
    padding: 10px 20px;
    line-height: 40px;
    margin-bottom: 0px;
    &__title {
      text-align: left;
      font-size: 14px;
      margin-right: 0;
    }
  }
  .payment-option,
  .method-option,
  .card-number {
    display: flex;
    align-items: center;
    border: 1px solid $c_border;
    border-radius: 8px;
    padding: 8px 10px;
    margin-bottom: 25px;
    width: 100%;
    cursor: pointer;
    &__title {
      flex-grow: 1;
      // font-size: 18px;
      line-height: 1;
      text-align: center;
      margin-right: 15px;
    }
    &__cancel {
      margin-left: auto;
      color: #9b9b9b;
      padding: 4px 6px;
      text-transform: uppercase;
      background-color: #fff;
      border: 2px solid #e1e1e1;
      border-radius: 30px;
      cursor: pointer;
    }
  }
  .payment-method {
    .title {
      text-align: center;
    }
    &__pay {
      margin-top: 110px;
      margin-bottom: 20px;
      &.with-info {
        margin-top: 55px;
      }
    }
    &__information {
      font-size: 14px;
      font-weight: 500;
      color: $c_grey;
      padding-left: 25px;
      & + & {
        margin-top: 15px;
      }
      &_autopay {
        padding-left: 0;
      }
      &_security {
        font-size: 16px;
        background-image: url('../../../assets/images/locked.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        margin-top: 30px;
      }
    }
  }
  .payment-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 20px;

    &__icon {
      font-size: 90px !important;
      margin: 20px;
    }
  }
}
</style>
