<template>
  <div class="patient-details">
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="fetchCardsLoading || saveNewCardLoading"
    ></v-progress-linear>

    <v-breadcrumbs :items="items" class="pl-0">
      <template v-slot:item="{ item }">
        <div class="d-flex justify-content-center align-items-center">
          <router-link :to="item.href" tag="a" class="" v-if="!item.disabled">
            {{ item.text }}
          </router-link>
          <p v-else class="ma-0">
            {{ item.text }}
          </p>
        </div>
      </template>
    </v-breadcrumbs>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h4>Contact</h4>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <div class="d-flex">
                  <p class="font-weight-bold ml-4 mb-0">Name:</p>
                  <p class="font-weight-bold ml-4 mb-0">
                    {{ patient?.firstname }} {{ patient?.lastname }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="d-flex">
                  <p class="font-weight-bold ml-4 mb-0">Patient ID:</p>
                  <p class="font-weight-bold ml-4 mb-0">
                    {{ patient?.patient_id }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="d-flex">
                  <p class="font-weight-bold ml-4 mb-0">Cellphone:</p>
                  <p class="font-weight-bold ml-4 mb-0">
                    {{ patient?.cellphone || 'N/A' }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="d-flex">
                  <p class="font-weight-bold ml-4 mb-0">Email:</p>
                  <p class="font-weight-bold ml-4 mb-0">
                    {{ patient?.email || 'N/A' }}
                  </p>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4">
                <v-btn
                  dense
                  color="primary"
                  text
                  @click="showManageCardModal = true"
                >
                  Manage Saved Cards
                </v-btn>
              </v-col>
              <v-col cols="12" sm="4">
                <v-switch
                  :input-value="patient?.opt_out"
                  label="Notifications"
                  @change="
                    setOptOut({
                      pid: patient.id,
                      opt_out: !patient?.opt_out,
                    })
                  "
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title> Invoices </v-card-title>
          <InvoicesTable
            :key="invoiceTableKey"
            :setPaymentOptions="setPaymentOptions"
            :toggleScheduleModal="toggleScheduleModal"
            :patientId="patient_id"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title> Payment Plans</v-card-title>
          <PaymentPlansTable
            :key="plansTableKey"
            :toggleScheduleModal="toggleScheduleModal"
            :patientId="patient_id"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title> Messages</v-card-title>
          <MessagesTable :patientId="patient_id" />
        </v-card>
      </v-col>
    </v-row>
    <manage-cards
      v-if="patient?.id && showManageCardModal"
      :pid="patient?.id"
      :showManageCardModal="showManageCardModal"
      @closeModal="showManageCardModal = false"
    ></manage-cards>

    <pay-modal
      :optionTitle="optionTitle"
      :paymentType="paymentType"
      :paymentData="paymentData"
      :dialog="payModalDialog"
      @updateDetails="updateDetails"
      :toggleDialog="togglePayModalDialog"
    ></pay-modal>

    <pay-details
      :amount="paymentData?.amount ?? 0"
      :balanceOperationIds="paymentData?.balanceOperationIds ?? []"
      :dialog="payDetailsDialog"
      @close="togglePayDetailsDialog"
      @updateAmount="updateAmount"
    ></pay-details>

    <scheduled-payment
      v-if="patient?.id && showManageScheduledPaymentModal"
      :pid="patient?.id"
      :patient_id="patient_id"
      :selectedPaymentPlan="selectedPaymentPlan"
      :showManageScheduledPaymentModal="showManageScheduledPaymentModal"
      @closeModal="closeScheduleModal"
    ></scheduled-payment>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as moment from 'moment-timezone';
import ManageCards from '@/components/manage-cards/manage-cards.vue';
import payModal from '@/components/modals/pay-modal/pay-modal.vue';
import payDetails from '@/components/modals/pay-modal/pay-details.vue';
import ScheduledPayment from '@/components/scheduled-payment/scheduled-payment.vue';
import { helpers } from '@/mixins/helpers';

import PaymentPlansTable from './payment-plans-table.vue';
import InvoicesTable from './invoices-table.vue';
import MessagesTable from './messages-table.vue';

const {
  mapGetters: invoiceGetters,
  mapActions: invoiceActions,
  mapMutations: invoiceMutations,
} = createNamespacedHelpers('invoices');
const { mapGetters: patientGetters, mapActions: patientActions } =
  createNamespacedHelpers('patient');

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'Patients',
  components: {
    ManageCards,
    payModal,
    InvoicesTable,
    PaymentPlansTable,
    ScheduledPayment,
    MessagesTable,
    payDetails,
  },
  mixins: [helpers],
  data() {
    return {
      selectedInvoices: [],
      patient_id: '',
      internal_patient_id: null,
      prevRoute: null,
      localTZ: 'UTC',
      showManageCardModal: false,
      payModalDialog: false,
      payDetailsDialog: false,
      paymentType: 'mixed',
      optionTitle: '',
      paymentData: null,
      invoiceTableKey: 0,
      plansTableKey: 0,
      showManageScheduledPaymentModal: false,
    };
  },
  beforeRouteEnter(_to, from, next) {
    next((vm) => {
      vm.prevRoute = from.fullPath;
    });
  },
  computed: {
    ...invoiceGetters({
      cards: 'CARDS',
      invoices: 'INVOICES',
      fetchCardsLoading: 'FETCH_CARDS_LOADING',
      saveNewCardLoading: 'SAVE_NEW_CARD_LOADING',
    }),
    ...patientGetters({
      patient: 'PATIENT',
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    items() {
      let invoicesList = '/payments?tab=invoices';
      if (this.prevRoute && this.prevRoute !== '/') {
        invoicesList = this.prevRoute;
      }
      return [
        {
          text: 'Invoices',
          disabled: false,
          href: invoicesList,
        },
        {
          text: 'Payment Details',
          disabled: true,
        },
      ];
    },
  },
  async created() {
    this.patient_id = this.$route.params?.patientId;
    this.internal_patient_id = this.$route.query?.pid;
    this.selectedInvoices = [];
    this.selectedPaymentPlan = null;
    this.setCards([]);

    await this.getPatient({ patientId: this.internal_patient_id });
    this.getPatientCards({ pid: this.internal_patient_id });
  },
  methods: {
    ...invoiceMutations({ setCards: 'SET_CARDS' }),
    ...invoiceActions(['chargeActions', 'sendReminder', 'getPatientCards']),
    ...patientActions(['getPatient', 'setOptOut']),
    formatDate(date) {
      if (!date) return '';
      else return moment(date).tz(this.localTZ).format('YYYY-MM-DD');
    },
    togglePayModalDialog() {
      this.payModalDialog = !this.payModalDialog;
    },
    togglePayDetailsDialog() {
      this.payDetailsDialog = !this.payDetailsDialog;
    },
    setPaymentOptions(data) {
      const {
        balance,
        charges,
        balanceOperationIds,
        showDetails = true,
      } = data;
      this.optionTitle = `$${this.priceFormat(balance)} Payment ${
        this.customer.CustomerName
      }`;
      // set paymentData
      let customerId = this.customer.customer_id;
      if (customerId === 0) {
        customerId = this.selectedCustomer.customer_id;
      }
      this.paymentData = {
        card_id: this.cards.find((c) => c.is_primary)?.id,
        amount: balance,
        currency: 'USD',
        customer_id: customerId,
        pid: this.patient.id,
        balanceOperationIds,
        charges,
        payType: this.paymentType,
        denominator: this.customer?.paymentDenominator,
        locID: this.customer?.CustomersFeature.defaultLocation,
      };

      if (showDetails) {
        this.togglePayDetailsDialog();
      } else {
        this.togglePayModalDialog();
      }
    },
    updateAmount(amount) {
      this.optionTitle = `$${this.priceFormat(amount)} Payment ${
        this.customer.CustomerName
      }`;
      this.paymentData = { ...this.paymentData, amount };
      this.togglePayDetailsDialog();
      this.togglePayModalDialog();
    },
    updateDetails() {
      this.optionTitle = '';
      this.paymentData = null;
      this.invoiceTableKey += 1;
    },
    toggleScheduleModal({ selectedInvoices, selectedPaymentPlan } = {}) {
      this.selectedInvoices = selectedInvoices || [];
      this.selectedPaymentPlan = selectedPaymentPlan || null;
      this.showManageScheduledPaymentModal =
        !this.showManageScheduledPaymentModal;
    },
    closeScheduleModal() {
      this.invoiceTableKey += 1;
      this.plansTableKey += 1;
      this.showManageScheduledPaymentModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './patient-details';
</style>
