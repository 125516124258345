<template>
  <div class="px-2">
    <div v-if="invoices.length">
      <div
        class="bill__wrap"
        :ref="i"
        v-for="(invoice, i) in invoices"
        :key="i"
      >
        <div
          class="bill__card"
          :class="{
            collapsed: collapsedData(i),
            uncollapsed: isData(i),
          }"
        >
          <div class="bill__status"></div>
          <div class="bill__content">
            <div class="bill__header">
              <div class="bill__info">
                <div class="bill__date">
                  {{ getDate(invoice.date_of_service) }}
                </div>

                <div class="bill__desc d-flex align-center">
                  <span> Invoice# {{ i }} </span>
                  <v-simple-checkbox
                    @input="$emit('selectInvoice', invoice)"
                    class="pl-5"
                    :value="isChecked(invoice)"
                  ></v-simple-checkbox>
                </div>
              </div>
            </div>
            <div
              class="bill__amount bill__amount-collapsed"
              v-show="collapsedData(i)"
            >
              Due ${{ priceFormat(invoiceBalance(invoice)) }}
            </div>
            <div class="bill__amount" v-show="isData(i)" @click="hideBill">
              Total Due ${{ priceFormat(invoiceBalance(invoice)) }}
            </div>

            <transition
              name="fade"
              @enter="enter"
              @afterEnter="afterEnter"
              @afterLeave="afterLeave"
              @leave="leave"
            >
              <div class="bill__table-wrap" v-show="isData(i)">
                <table class="details-table bill__table" ref="table">
                  <tbody v-for="(charge, j) in invoice.charges" :key="j">
                    <tr>
                      <td colspan="2" class="bill__table-description">
                        {{ charge.procedure_description }} ({{
                          charge.procedure_code
                        }})
                      </td>
                    </tr>
                    <tr>
                      <td class="noborder">Total Charge</td>
                      <td class="noborder">
                        ${{ priceFormat(charge.patient_balance) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="noborder">Your Previous Payment</td>
                      <td class="noborder">
                        -${{ priceFormat(charge.patient_paid) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="noborder">Insurance & Adjustment</td>
                      <td class="noborder">
                        -${{ priceFormat(charge.adjusted_amount) }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Your balance</td>
                      <td>${{ priceFormat(invoiceBalance(invoice)) }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </transition>
            <button
              class="btn btn-third bill__button"
              v-show="isData(i)"
              @click.prevent="hideBill"
            >
              Hide details
            </button>
            <button
              class="btn btn-third bill__button"
              v-show="collapsedData(i)"
              @click.prevent="showBill(i)"
            >
              View full details
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="mb-5">There are no standalone invoices to select.</div>
  </div>
</template>

<script>
import { helpers } from '@/mixins/helpers';
import moment from 'moment';

export default {
  name: 'InvoiceList',
  mixins: [helpers],
  props: {
    invoices: {
      type: Array,
      required: true,
    },
    selectedInvoices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hideBills: false,
      openedId: null,
      newId: null,
      openedElementHeight: null,
      closedElementHeight: null,
      message: null,
    };
  },
  computed: {
    balance() {
      const invoices = this.invoices || [];
      const balance = invoices
        .map((invoice) =>
          invoice.charges.reduce((a, c) => {
            if (c.patient_balance > 0) {
              return a + c.patient_balance;
            }
            return a;
          }, 0.0)
        )
        .reduce((a, c) => a + c, 0.0);
      return Number(balance).toFixed(2);
    },
    openedIdHasValue() {
      return this.openedId !== null && this.openedId !== undefined;
    },
  },
  methods: {
    enter(el) {
      this.openedElementHeight = el.clientHeight;
      el.style.height = '0px';
      setTimeout(() => {
        el.style.height = this.openedElementHeight + 'px';
      });
    },
    leave(el) {
      this.closedElementHeight = el.clientHeight;
      el.style.height = this.closedElementHeight + 'px';
      setTimeout(() => {
        el.style.height = '0px';
      });
    },
    afterEnter(el) {
      el.style.height = 'auto';
    },
    afterLeave(el) {
      el.style.height = 'auto';
    },
    invoiceBalance(invoice) {
      if (invoice.charges) {
        const balance = invoice.charges?.reduce((a, c) => {
          if (c.patient_balance > 0) {
            return a + c.patient_balance;
          }
          return a;
        }, 0.0);
        return Number(balance).toFixed(2);
      }

      return invoice.patient_balance;
    },
    collapsedData(i) {
      return !this.openedIdHasValue || (this.openedId && this.openedId !== i);
    },
    isData(i) {
      return this.openedIdHasValue && this.openedId === i;
    },
    getDate(date) {
      return moment(date).utc().format('L');
    },
    showBill(id) {
      this.openedId = id;
      if (id === 'prepay') {
        setTimeout(() => {
          this.$refs[id].scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'nearest',
          });
        }, 300);
      } else {
        setTimeout(() => {
          this.$refs[id][0].scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'nearest',
          });
        }, 300);
      }
    },
    hideBill() {
      this.openedId = null;
    },
    isChecked(invoice) {
      return !!this.selectedInvoices.find(
        (i) => i.visit_id === invoice.visit_id
      );
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/variables.scss';

.bill {
  &__wrap {
    padding: 10px 0;
    &.empty {
      padding: 0;
    }
  }
  &__card {
    display: flex;
    overflow: hidden;
    width: 100%;
    background-color: white;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    // margin: 10px 0;
    transition: 1s all;
    &.opened {
      .bill__amount {
        margin: 30px 0;
      }
    }
  }
  &__table {
    text-align: left;
    color: $c_grey_2;
    position: relative;
    display: table;
    &-wrap {
      width: 100%;
      overflow: hidden;
    }
    tbody {
      max-width: 100%;
      &:first-child {
        tr td {
          border-top: none;
        }
      }
    }
    .noborder {
      border-top: none;
    }
    &-description {
      text-align: center;
      font-weight: bold;
    }
  }
  &__status {
    width: 30px;
    position: relative;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: $c_main;

    &:after {
      position: absolute;
      content: 'Ready for payment';
      font-size: 11px;
      text-transform: uppercase;
      color: white;
      transform: rotate(-90deg);
      white-space: nowrap;
      left: -43px;
      top: calc(50% - 9px);
    }
    &-prepay {
      width: 30px;
      position: relative;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background-color: #6667e6;
      &:after {
        position: absolute;
        content: 'ob payment';
        text-transform: uppercase;
        color: white;
        transform: rotate(-90deg);
        white-space: nowrap;
        left: -20px;
        top: calc(50% - 9px);
      }
    }
  }
  &__content {
    padding: 7px 16px 10px 16px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    transition: 1s all;
    .details-info__body {
      text-align: left;
    }
  }
  &__prepay-wrap {
    overflow: hidden;
  }
  &__header {
    width: 100%;
    margin-bottom: 0px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
  }
  &__desc {
    font-size: 12px;
    color: $c_grey;
    text-align: left;
  }
  &__date {
    font-size: 18px;
  }
  &__body {
    width: 100%;
    text-align: center;
  }
  &__amount {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -1px;
    margin: 5px 0 10px 0;
    &-collapsed {
      margin-bottom: 0;
    }
  }
  &__button {
    margin: 7px;
    font-size: 16px;
    text-transform: uppercase;
  }
  &__button-blue {
    font-size: 16px;
    text-transform: uppercase;
  }
}
</style>
