<template>
  <div>
    <v-dialog v-model="modalOpen" width="500">
      <v-card class="manage-cards">
        <div class="manage-cards__list">
          <div class="d-flex flex-column">
            <div
              v-for="(c, index) in cards"
              :key="index"
              class="card-number"
              :class="{
                selected: c.id === (selectedCard && selectedCard.id),
              }"
              @click="toggleSelectedCard(c)"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="card-number__logo" v-bind="attrs" v-on="on">
                    <template v-if="c.type">
                      <img
                        :src="
                          require(`@/assets/images/card-types/${c.type}-dark.png`)
                        "
                        alt=""
                      />
                    </template>
                    <template v-else>
                      <img src="@/assets/images/card-types/empty.png" alt="" />
                    </template>
                  </div>

                  <div class="card-number__title" v-bind="attrs" v-on="on">
                    <template v-if="c.lastfour">
                      xxxx-xxxx-xxxx-{{ c.lastfour }}
                    </template>
                    <template v-else> Enter card number </template>
                  </div>
                </template>

                <span>Expires: {{ `${c.exp_month}/${c.exp_year}` }}</span>
              </v-tooltip>

              <div class="card-number__actions">
                <v-icon small color="success" v-if="c.is_primary">
                  mdi-checkbox-marked-circle-outline
                </v-icon>
                <v-btn
                  icon
                  v-if="!c.is_primary"
                  :disabled="deleteCardLoading"
                  @click="removeCard(c)"
                  :loading="deleteCardLoading === c.id"
                  color="error"
                >
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex">
          <v-btn
            outlined
            small
            color="primary"
            class="mr-1"
            @click="selectPrimaryCard"
            :disabled="!selectedCard || selectedCard.is_primary"
            :loading="primaryCardLoading"
          >
            Set Default Card
          </v-btn>
        </div>

        <div class="mt-10 form-container">
          <h2 class="text-center">Add A New Card</h2>
          <credit-card-form
            :loading="saveNewCardLoading"
            @submit="addCardInfo"
          ></credit-card-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import creditCardForm from './credit-card-form/credit-card-form.vue';

const { mapGetters: invoiceGetters, mapActions: invoiceActions } =
  createNamespacedHelpers('invoices');

export default {
  name: 'manageCards',
  props: {
    pid: {
      required: true,
      type: Number,
    },
    showManageCardModal: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isDeleting: false,
      selectedCard: null,
    };
  },
  components: {
    creditCardForm,
  },
  computed: {
    ...invoiceGetters({
      cards: 'CARDS',
      deleteCardLoading: 'DELETE_CARD_LOADING',
      primaryCardLoading: 'PRIMARY_CARD_LOADING',
      saveNewCardLoading: 'SAVE_NEW_CARD_LOADING',
    }),
    modalOpen: {
      get() {
        return this.showManageCardModal;
      },
      set() {
        this.$emit('closeModal');
      },
    },
  },
  methods: {
    ...invoiceActions(['saveNewCard', 'deleteCard', 'setPrimaryCard']),
    removeCard(card) {
      this.toggleSelectedCard(null);
      const message = `Are you sure you want to delete xxxx-xxxx-xxxx-${card.lastfour} card?`;

      if (confirm(message)) {
        this.deleteCard({ pid: this.pid, cardID: card.id });
      }
    },
    toggleSelectedCard(card) {
      this.selectedCard = card;
    },
    async selectPrimaryCard() {
      this.setPrimaryCard({ pid: this.pid, cardID: this.selectedCard.id });
      this.toggleSelectedCard(null);
    },
    async addCardInfo(card) {
      await this.saveNewCard({ ...card, pid: this.pid });
      this.modalOpen = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/variables.scss';
@import './manage-cards.scss';

.form-container {
  border: 1px solid $c_border;
  padding: 10px 50px 20px;
}
</style>
