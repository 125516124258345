<template>
  <v-form
    v-model="isFormValid"
    @submit="onSubmit"
    class="form-width"
    ref="form">
    <v-text-field
      :v-model="card.number"
      label="Credit Card Number"
      name="cardnumber"
      @input="fakeCardNumber"
      @change="fakeCardNumber"
      outlined
      dense
      :rules="[rules.required, rules.cardNumber]"
      class="mt-2" />

    <v-text-field
      label="Cardholder Name"
      name="ccname"
      v-model="card.name"
      :rules="[rules.required]"
      outlined
      dense
      class="mt-2" />

    <div class="mt-2">
      <label class="form-label">Expiration</label>
      <div class="d-flex">
        <v-select
          v-model="card.exp.month"
          :items="months"
          label="Month"
          outlined
          dense
          class="month-selector mr-2"
          :rules="[rules.required]"></v-select>

        <span class="separator"></span>

        <v-select
          v-model="card.exp.year"
          :items="expYears"
          item-text="name"
          outlined
          dense
          label="Year"
          class="year-selector ml-2"
          :rules="[rules.required]"></v-select>
      </div>
    </div>

    <v-text-field
      class="mt-2"
      label="Billing Zipcode"
      v-model="card.zipcode"
      outlined
      dense
      :rules="[rules.required]" />

    <div class="d-flex justify-center">
      <v-btn
        v-if="!loading"
        class="mt-3"
        type="submit"
        color="primary"
        :disabled="!isFormValid || hasEmptyFields">
        Save Card
      </v-btn>
      <v-progress-circular
        v-else
        indeterminate
        color="primary"
        size="24"></v-progress-circular>
    </div>
  </v-form>
</template>

<script>
const valid = require('card-validator');

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      card: {
        number: null,
        name: null,
        type: null,
        exp: {
          month: null,
          year: null,
        },
        valid: true,
        zipcode: null,
      },
      currentYear: null,
      currentMonth: null,
      isFormValid: true,
      rules: {
        required: (value) => !!value || 'Required.',
        cardNumber: (value) =>
          valid.number(value).isValid || 'Invalid Card Number',
      },
    };
  },
  computed: {
    cardNumber() {
      return this.card.number;
    },
    cardMonth() {
      return this.card.exp.month;
    },
    cardYear() {
      return this.card.exp.year;
    },
    expYears() {
      const expYears = [];
      const expiryMonth = this.card.exp.month;
      for (let i = 1; i < 16; i++) {
        expYears.push(this.currentYear + i);
      }

      if (!expiryMonth || expiryMonth > this.currentMonth) {
        expYears.unshift(this.currentYear);
        return expYears;
      }

      return expYears;
    },
    months() {
      const expiryYear = this.card.exp.year;
      if (!expiryYear || expiryYear > this.currentYear) {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      }

      const expMonths = [];
      for (let i = this.currentMonth; i < 12; i++) {
        expMonths.push(i + 1);
      }
      return expMonths;
    },
    hasEmptyFields() {
      const card = this.card;
      const expiry = this.card.exp;

      return (
        !card.number ||
        !card.name ||
        !card.zipcode ||
        !expiry.month ||
        !expiry.year
      );
    },
  },
  mounted() {
    this.currentYear = new Date().getFullYear();
    this.currentMonth = new Date().getMonth();
  },
  methods: {
    fakeCardNumber(event) {
      let $el = event;
      if (!$el) return;
      let newVal = $el
        .replace(/\D/gi, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();
      $el = newVal;
      this.card.number = newVal.replace(/\D+/g, '');
      this.card.valid = true;
    },
    monthFormat(val) {
      return ('0' + val).slice(-2);
    },
    async onSubmit(e) {
      e.preventDefault();
      const cardNumber = this.card.number;

      const cardDetails = {
        expMonth: this.monthFormat(this.card.exp.month),
        expYear: this.card.exp.year,
        type: valid.number(cardNumber).card.type,
        lastfour: cardNumber.toString().slice(-4),
        cardHolderName: this.card.name,
        postalCode: this.card.zipcode,
        cardNumber,
      };

      await this.$emit('submit', cardDetails);
      this.$refs.form.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-width {
  width: 300px;
}
.month-selector {
  width: 50px;
}
.year-selector {
  width: 100px;
}
</style>
