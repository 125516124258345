<template>
  <div>
    <v-card class="mt-1">
      <v-card-title>
        <h5>Invoice No. {{ invoiceId }} Charges Details</h5>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="charges"
        item-key="id"
        :hide-default-footer="true"
        :items-per-page="-1"
      >
        <template v-slot:body.append>
          <tr>
            <td class="font-weight-bold">Total:</td>
            <td class="font-weight-bold">{{ totalAmount }}</td>
          </tr>
        </template>

        <template v-slot:item.date_of_service="{ item }">
          <div>
            {{ formatDate(item.date_of_service) }}
          </div>
        </template>

        <template v-slot:item.total_charge="{ item }">
          <div>
            {{ item.total_charge.toFixed(2) }}
          </div>
        </template>

        <template v-slot:item.patient_balance="{ item }">
          <div>
            {{ item.patient_balance.toFixed(2) }}
          </div>
        </template>

        <template v-slot:item.expired="{ item }">
          <v-chip
            v-if="isExpired(item.invoice_type, item.date_of_service)"
            outlined
            color="red"
            text-color="red"
          >
            Expired
          </v-chip>
        </template>

        <template v-slot:item.statusIsPaid="{ item }">
          <v-chip
            v-if="item?.statusIsPaid"
            outlined
            color="green"
            text-color="green"
          >
            PAID
          </v-chip>
          <v-chip v-else outlined color="red" text-color="red"> UNPAID </v-chip>
        </template>

        <template v-slot:item.action="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs">
                <v-icon> mdi-dots-horizontal </v-icon>
              </v-btn>
            </template>
            <div class="d-flex flex-column Patient-Details__menu">
              <v-btn
                outlined
                text
                color="primary"
                small
                class="pa-5"
                :disabled="isChargePaymentDisabled(item)"
                @click.prevent="makePayment(item.id)"
              >
                Pay Charge
              </v-btn>
              <v-btn
                small
                color="success"
                class="ma-1"
                @click="updateCharge('paid', item)"
                :disabled="item.statusIsPaid"
              >
                Mark As Paid
              </v-btn>
              <v-btn
                small
                class="ma-1"
                @click="editCharge(item)"
                v-if="(item.invoice_type === 'prePay')"
              >
                Edit Balance
              </v-btn>
              <v-btn
                small
                color="error"
                class="ma-1"
                @click="updateCharge('delete', item)"
              >
                Delete
              </v-btn>
              <v-btn
                small
                color="error"
                class="ma-1"
                @click="updateCharge('expire_charge', item)"
                :disabled="item.status !== 'ACTIVE'"
              >
                Expire Charge
              </v-btn>
            </div>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <invoice-activities
      v-if="patient.id"
      :patient_id="patient_id"
      :pid="patient.id"
      :invoice_id="invoiceId"
    ></invoice-activities>

    <EditChargeAmount
      :dialog="!!selectedCharge"
      :charge="selectedCharge"
      @close="selectedCharge = null"
      @save="(charge) => updateCharge('updateAmount', charge)"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as moment from 'moment-timezone';
import { helpers } from '@/mixins/helpers';
import InvoiceActivities from '@/components/invoice-activities/invoice-activities.vue';
import EditChargeAmount from '@/components/modals/edit-charge-amount/edit-charge-amount.vue';

const { mapGetters: invoiceGetters, mapActions: invoiceActions } =
  createNamespacedHelpers('invoices');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapGetters: patientGetters } = createNamespacedHelpers('patient');

export default {
  name: 'InvoiceDetails',
  components: {
    InvoiceActivities,
    EditChargeAmount,
  },
  props: {
    setPaymentOptions: {
      type: Function,
      required: true,
    },
    patientId: {
      type: String,
      required: true,
    },
    charges: {
      type: Array,
      required: true,
    },
    invoiceId: {
      type: Number,
      required: true,
    },
  },
  mixins: [helpers],
  data() {
    return {
      patient_id: '',
      prevRoute: null,
      localTZ: 'UTC',
      showManageScheduledPaymentModal: false,
      paymentType: 'mixed',
      optionTitle: '',
      paymentData: null,
      selectedCharge: null,
    };
  },
  computed: {
    ...invoiceGetters({
      chargeUpdateLoading: 'CHARGE_UPDATE_LOADING',
    }),
    ...patientGetters({
      patient: 'PATIENT',
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    headers() {
      const defaults = { sortable: false, filterable: false };
      return [
        {
          text: 'Description',
          value: 'procedure_description',
          ...defaults,
        },
        {
          text: 'Total Charge (USD)',
          value: 'total_charge',
          ...defaults,
        },
        {
          text: 'Remaining balance (USD)',
          value: 'patient_balance',
          ...defaults,
        },
        {
          text: 'Charge Id',
          value: 'charge_id',
          ...defaults,
        },
        {
          text: 'Date of service',
          value: 'date_of_service',
          ...defaults,
        },
        {
          text: 'Status',
          value: 'statusIsPaid',
          ...defaults,
        },
        {
          text: '',
          value: 'expired',
          ...defaults,
        },
        {
          text: '',
          value: 'action',
          ...defaults,
        },
      ];
    },
    totalAmount() {
      return Number(
        this.charges.reduce((a, b) => {
          if (!b.statusIsPaid) {
            return a + b.patient_balance;
          }
          return a;
        }, 0)
      ).toFixed(2);
    },
    disableMarkAllAsPaid() {
      return this.charges.every((c) => c.statusIsPaid === true);
    },
  },
  async created() {
    this.patient_id = this.patientId;
  },
  methods: {
    ...invoiceActions(['chargeActions']),
    formatDate(date) {
      if (!date) return '';
      else return moment(date).tz(this.localTZ).format('YYYY-MM-DD');
    },
    isExpired(type, dateOfService) {
      return type === 'prePay' && new Date(dateOfService) < new Date();
    },
    async updateCharge(action, charge) {
      await this.chargeActions({
        action,
        balance_operation_id: charge?.id,
        visit_id: this.invoiceId,
        patient_id: this.patient.patient_id,
        total_charge: charge.total_charge,
      });
      this.$emit('updateList', {});
    },
    makePayment(balanceOperationId) {
      const charge = this.charges.find((c) => c.id === balanceOperationId);
      const balance = charge.patient_balance;
      const charges = charge.charge_id?.toString();
      const balanceOperationIds = balanceOperationId;
      this.setPaymentOptions({ balance, charges, balanceOperationIds });
    },
    isChargePaymentDisabled(charge) {
      let expired = false;
      if (charge.invoice_type === 'prePay') {
        expired = new Date(charge.date_of_service) < new Date();
      }
      return charge.statusIsPaid || expired;
    },
    editCharge(charge) {
      this.selectedCharge = charge;
    },
  },
};
</script>

<style lang="scss" scoped>
.Patient-Details {
  padding-top: 20px;

  &__menu {
    background-color: white;
  }

  &__filter-row {
    padding: 15px 0;
  }
}
</style>
