<template>
  <v-dialog
    v-model="modalOpen"
    transition="dialog-bottom-transition"
    @click:outside="modalOpen = false"
    :width="600"
  >
    <div class="autopay d-flex flex-column">
      <h3 class="mt-4 align-self-center">
        {{ isEdit ? 'Edit' : 'Create' }} Payment Plan
      </h3>
      <h2 class="my-2 align-self-center">Balance ${{ balance }}</h2>

      <v-stepper v-model="planStepper" vertical>
        <v-stepper-step :step="1" :complete="planStepper > 0" v-if="isEdit">
          Change plan status
        </v-stepper-step>
        <v-stepper-content step="0" v-if="isEdit">
          <div class="mx-auto mt-5 mb-1">
            <v-select
              :items="statuses"
              outlined
              dense
              item-text="name"
              item-value="value"
              :value="status"
              @input="(val) => updateStatus(val)"
            ></v-select>
          </div>
          <div class="d-flex align-content-start">
            <v-btn
              color="primary"
              @click="planStepper = 1"
              :disabled="!selectedInvoices.length"
            >
              Continue
            </v-btn>
            <v-btn text @click="close"> Cancel </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step :complete="planStepper > 1" :step="isEdit ? 2 : 1">
          Select statements to add
        </v-stepper-step>
        <v-stepper-content step="1">
          <invoiceList
            :invoices="invoices"
            :selectedInvoices="selectedInvoices"
            @selectInvoice="addSelectedInvoice"
          />
          <div class="d-flex align-content-start">
            <v-btn
              color="primary"
              @click="planStepper = 2"
              :disabled="!selectedInvoices.length"
            >
              Continue
            </v-btn>
            <v-btn text @click="isEdit ? (planStepper = 0) : close()">
              {{ isEdit ? 'Back' : 'Cancel' }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step :complete="planStepper > 2" :step="isEdit ? 3 : 2">
          Enter payment amount
        </v-stepper-step>
        <v-stepper-content step="2">
          <div class="ma-5">
            <v-text-field
              type="number"
              :disabled="isPaidOrCancelledPlan"
              min="0"
              prefix="$"
              suffix="/Mo"
              v-model="regular_payment_amount"
              :rules="inputRules"
              label="Monthly Payment Amount"
            ></v-text-field>
          </div>

          <div class="d-flex align-content-start">
            <v-btn
              color="primary"
              @click="planStepper = 3"
              :disabled="regular_payment_amount <= 0"
            >
              Continue
            </v-btn>
            <v-btn text @click="planStepper = 1"> Back </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step :complete="planStepper > 3" :step="isEdit ? 4 : 3">
          Select payment start date
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-date-picker
            :disabled="isPaidOrCancelledPlan"
            v-model="paymentDate"
            :allowed-dates="allowedDates"
            @change="showFirstDateSelector = false"
          >
          </v-date-picker>
          <div class="d-flex align-content-start">
            <v-btn
              color="primary"
              @click="planStepper = 4"
              :disabled="!paymentDate"
            >
              Continue
            </v-btn>
            <v-btn text @click="planStepper = 2"> Back </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step :complete="planStepper > 4" :step="isEdit ? 5 : 4">
          Select or Add payment information
        </v-stepper-step>
        <v-stepper-content step="4">
          <div class="d-flex justify-space-between">
            <div v-if="card" class="card-number">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="card-number__logo" v-bind="attrs" v-on="on">
                    <template v-if="card.type">
                      <img
                        :src="
                          require(`@/assets/images/card-types/${card.type}-dark.png`)
                        "
                        alt=""
                      />
                    </template>
                    <template v-else>
                      <img src="@/assets/images/card-types/empty.png" alt="" />
                    </template>
                  </div>

                  <div class="card-number__title" v-bind="attrs" v-on="on">
                    <template v-if="card.lastfour">
                      xxxx-xxxx-xxxx-{{ card.lastfour }}
                    </template>
                    <template v-else> Enter card number </template>
                  </div>
                </template>

                <span>Expires: {{ `${card.exp_month}/${card.exp_year}` }}</span>
              </v-tooltip>
              <v-icon small color="success">
                mdi-checkbox-marked-circle-outline
              </v-icon>
            </div>

            <v-btn
              dense
              color="primary"
              class="py-6"
              outlined
              text
              @click="showManageCardModal = true"
            >
              {{ card ? 'Change Card' : 'Manage Cards' }}
            </v-btn>
          </div>

          <manage-cards
            v-if="patient?.id && showManageCardModal && planStepper === 4"
            :pid="patient?.id"
            :showManageCardModal="showManageCardModal"
            @closeModal="showManageCardModal = false"
          ></manage-cards>
        </v-stepper-content>

        <div class="d-flex justify-center" v-if="planStepper === 4">
          <v-btn
            color="primary"
            @click.prevent="savePaymentPlan"
            :disabled="isEmptyCard || isPaidOrCancelledPlan"
          >
            {{
              selectedPaymentPlan
                ? 'Confirm Payment Plan'
                : 'Create Payment Plan'
            }}
          </v-btn>
          <v-btn text @click="planStepper = 3"> Back </v-btn>
        </div>
      </v-stepper>
    </div>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment';
import { helpers } from '@/mixins/helpers';
import ManageCards from '@/components/manage-cards/manage-cards.vue';
import InvoiceList from './invoice-list.vue';

const { mapGetters: invoiceGetters, mapActions: invoiceActions } =
  createNamespacedHelpers('invoices');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapGetters: patientGetters } = createNamespacedHelpers('patient');

export default {
  name: 'AutopayOptionsPopup',
  components: {
    ManageCards,
    InvoiceList,
  },
  mixins: [helpers],
  props: {
    showManageScheduledPaymentModal: {
      required: true,
      type: Boolean,
    },
    selectedPaymentPlan: {
      required: false,
      type: Object,
    },
    pid: {
      required: true,
      type: Number,
    },
    patient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      updatingDataLoading: false,
      message: {
        title: '',
        text: '',
      },
      showManageCardModal: false,
      payment_batch: {
        payment_type: 'mixed',
        notes: null,
        batch_is_active: true,
        total_amount: 0.0,
        regular_payment_amount: 0.0,
        paid_amount: 0.0,
        schedule: '1',
        last_payment_date: null,
        next_payment_date: null,
      },
      openedId: null,
      showFirstDateSelector: false,
      paymentDate: null,
      regular_payment_amount: 0.0,
      status: 'active',
      statuses: [
        { name: 'Active', value: 'active' },
        { name: 'Disabled', value: 'disabled' },
        { name: 'Paid', value: 'paid' },
        { name: 'Cancelled', value: 'cancelled' },
      ],
      selectedInvoices: [],
      planStepper: 1,
    };
  },
  async mounted() {
    this.setupData();
    this.fetchStandaloneInvoices({
      page: 1,
      limit: 10,
      patientId: this.patient_id,
    });
    if (this.selectedPaymentPlan) {
      this.planStepper = 0;
      this.status = this.selectedPaymentPlan.status;
      this.selectedInvoices = this.selectedPaymentPlan.invoices;
    }
  },
  computed: {
    ...invoiceGetters({
      cards: 'CARDS',
      createPaymentPlanLoading: 'CREATE_PAYMENT_PLAN_LOADING',
      allInvoices: 'STANDALONE_INVOICES',
    }),
    ...patientGetters({
      patient: 'PATIENT',
    }),
    ...customerGetters({ customer: 'CUSTOMER' }),
    card() {
      if (!this.cards.length) return null;
      return this.cards.find((c) => c.is_primary);
    },
    isEmptyCard() {
      return this.card ? false : true;
    },
    invoices() {
      if (this.selectedPaymentPlan?.invoices) {
        return this.selectedPaymentPlan.invoices;
      }

      return this.allInvoices ?? [];
    },
    modalOpen: {
      get() {
        return this.showManageScheduledPaymentModal;
      },
      set() {
        this.$emit('closeModal');
      },
    },
    paymentDateFieldText() {
      if (this.isEdit) {
        return 'Next Payment Date';
      }
      return 'First Payment From';
    },
    isEdit() {
      return this.selectedPaymentPlan ? true : false;
    },
    isPaidOrCancelledPlan() {
      const status = this.selectedPaymentPlan?.status;
      return status === 'paid' || status === 'cancelled';
    },
    balance() {
      if (this.selectedPaymentPlan?.balance) {
        return this.selectedPaymentPlan.balance;
      }
      return this.selectedInvoices.reduce(
        (acc, cur) => acc + +cur.patient_balance,
        0
      );
    },
    charges() {
      let chargesIDs = [];
      let invoices = [];
      if (this.selectedInvoices && this.selectedInvoices.length > 0) {
        invoices = this.selectedInvoices;
      } else {
        invoices = this.selectedPaymentPlan.invoices;
      }

      for (let invoice in invoices) {
        const charges = invoice.charges.map((proc) => proc.charge_id);
        chargesIDs = chargesIDs.concat(charges);
      }
      chargesIDs = chargesIDs.flat();
      return chargesIDs;
    },
    inputRules() {
      const rules = [
        (v) => !!v || 'Required',
        (v) => v >= 0 || 'Cannot be less than 0.00',
      ];

      if (!this.balance) return rules;
      const sum = this.balance;
      return rules.concat((v) =>
        sum ? v <= parseFloat(sum) || 'Cannot be above ' + sum : ''
      );
    },
    dateRules() {
      const rules = [
        (v) => !!v || 'Required',
        (v) => this.allowedDates(v) || 'Cannot be in the past',
      ];

      return rules;
    },
  },
  methods: {
    ...invoiceActions([
      'createPaymentPlan',
      'updatePaymentPlan',
      'fetchStandaloneInvoices',
    ]),
    isData(i) {
      return (
        (this.openedId !== undefined || this.openedId !== null) &&
        this.openedId === i
      );
    },
    showDate() {
      if (this.$refs.dateInput) {
        this.$refs.dateInput.showPicker();
      }
    },
    async close() {
      this.modalOpen = false;
      this.reset();
    },
    addSelectedInvoice(invoice) {
      const addedIndex = this.selectedInvoices.findIndex(
        (i) => i.visit_id === invoice.visit_id
      );
      if (addedIndex > -1) {
        this.selectedInvoices.splice(addedIndex, 1);
      } else {
        this.selectedInvoices.push(invoice);
      }
    },
    showBill(id) {
      this.openedId = id;
    },
    hideBill() {
      this.openedId = null;
    },
    calcInvoiceBalance(invoice) {
      return invoice.charges.reduce((a, c) => a + c.patient_balance, 0);
    },
    updateStatus(status) {
      this.status = status;
    },
    enter(el) {
      this.openedElementHeight = el.clientHeight;
      el.style.height = '0px';
      setTimeout(() => {
        el.style.height = this.openedElementHeight + 'px';
      });
    },
    leave(el) {
      this.closedElementHeight = el.clientHeight;
      el.style.height = this.closedElementHeight + 'px';
      setTimeout(() => {
        el.style.height = '0px';
      });
    },
    afterEnter(el) {
      el.style.height = 'auto';
    },
    afterLeave(el) {
      el.style.height = 'auto';
    },
    formatDate(date) {
      if (!date) return 'No date selected';
      else return moment(date).utc().format('L');
    },
    setupData(reset = false) {
      if (this.selectedPaymentPlan && !reset) {
        this.payment_batch = this.selectedPaymentPlan;
        this.paymentDate = this.selectedPaymentPlan.next_payment_date;
        this.paymentDate = this.paymentDate
          ? moment(this.paymentDate).format('YYYY-MM-DD')
          : null;
        this.regular_payment_amount =
          this.selectedPaymentPlan.regular_payment_amount;
        this.status = this.selectedPaymentPlan.status;
        return;
      }

      const defaultDate = moment().date();
      this.payment_batch = {};

      if (reset) {
        this.payment_batch = { ...this.selectedPaymentPlan };
        this.paymentDate = null;
      }

      const denominator = this.customer.paymentDenominator
        ? this.customer.paymentDenominator
        : 6;

      this.regular_payment_amount = parseFloat(
        (this.balance / denominator).toFixed(2)
      );

      this.payment_batch = {
        payment_type: 'mixed',
        ...this.payment_batch,
        notes: null,
        batch_is_active: true,
        total_amount: this.balance,
        regular_payment_amount: this.regular_payment_amount,
        paid_amount: 0,
        schedule: defaultDate,
        last_payment_date: null,
        next_payment_date: null,
      };
    },
    allowedDates(val) {
      const today = moment().startOf('day');
      return moment(val).isAfter(today);
    },
    async savePaymentPlan() {
      const schedule = this.paymentDate ? moment(this.paymentDate).date() : 31;
      const payload = {
        schedule,
        regular_payment_amount: this.regular_payment_amount,
        status: this.status.toLowerCase(),
        start_from: this.paymentDate,
      };

      if (!this.selectedPaymentPlan) {
        const data = {
          pid: this.pid,
          parameters: {
            ...payload,
            invoiceIds: this.selectedInvoices.map((i) => i.visit_id).join(','),
          },
        };
        await this.createPaymentPlan({ data });
      } else {
        const data = {
          pid: this.pid,
          parameters: {
            ...payload,
            id: this.selectedPaymentPlan.id,
          },
        };
        await this.updatePaymentPlan({
          paymentPlanId: this.selectedPaymentPlan.id,
          data,
        });
      }

      this.modalOpen = false;
    },
    openCardModal() {
      this.showManageCardModal = true;
    },
    reset() {
      this.paymentDate = null;
      this.selectedInvoices = [];
      this.planStepper = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
@import './scheduled-payment.scss';
@import '../manage-cards/manage-cards.scss';
</style>
