<template>
  <div>
    <v-dialog :value="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h6 text-center">
          Edit Charge Amount
        </v-card-title>

        <v-form v-model="valid">
          <div class="d-flex pa-5 justify-space-between align-center">
            <p class="text-body-2">Total Charge</p>
            <v-text-field
              class="pl-5"
              :rules="rules"
              suffix="$"
              reverse
              dense
              v-model="newAmount"
            />
          </div>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('close')" :disabled="false"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <action-prompt
      v-model="showConfirmationModal"
      v-if="showConfirmationModal"
      action-text="Update"
      :action="save"
      header="Update Charge"
      body="TotalCharge is less than amount already paid by patient. Proceed?"
      action-type="error"
    />
  </div>
</template>

<script>
import { helpers } from '@/mixins/helpers';
import ActionPrompt from '@/components/modals/action-prompt/action-prompt.vue';

export default {
  name: 'EditChargeAmount',
  components: {
    ActionPrompt,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    charge: {
      required: true,
    },
  },
  mixins: [helpers],
  data() {
    return {
      valid: false,
      showConfirmationModal: false,
      newAmount: 0,
      rules: [
        (value) => !!value || 'Required.',
        (v) => !Number.isNaN(Number(v)) || 'Only numbers allowed',
        (v) => Number(v) > 0 || 'Amount must be greater than 0',
      ],
    };
  },
  watch: {
    dialog(newValue) {
      if (newValue && this.charge?.total_charge) {
        this.newAmount = this.amountFormat(this.charge?.total_charge);
      } else {
        // reset
        this.newAmount = 0;
      }
    },
  },
  methods: {
    save() {
      if (
        this.newAmount < this.charge.patient_paid &&
        !this.showConfirmationModal
      ) {
        this.showConfirmationModal = true;
        return;
      }
      this.showConfirmationModal = false;
      this.$emit('save', {
        ...this.charge,
        total_charge: Number(this.newAmount),
      });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './edit-charge-amount';
</style>
