<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="900"
    :value="dialog"
    @click:outside="$emit('close')"
    content-class="modal__container"
  >
    <h3 class="text-center">Charges Included</h3>

    <ul>
      <li v-for="charge in charges" :key="charge.id">
        <div class="py-5 d-flex justify-space-between">
          <span class="flex-grow-1 flex-shrink-1">
            {{ charge.procedure_description || charge.procedure_code || 'N/A' }}
          </span>
          <span class="mx-5"> - </span>
          <strong>${{ charge.patient_balance }}</strong>
        </div>
        <v-divider></v-divider>
      </li>
    </ul>

    <v-form v-model="valid">
      <div class="d-flex py-10 justify-space-between align-center">
        <p class="text-body-2">Pay Amount (USD)</p>
        <v-text-field
          class="pl-5"
          :rules="rules"
          prefix="$"
          :hint="disabled ? 'Double click to change amount' : ''"
          persistent-hint
          dense
          :readonly="disabled"
          :outlined="!disabled"
          v-model="newAmount"
          v-on:dblclick="disabled = false"
        />
      </div>

      <div class="mt-5 d-flex justify-end">
        <v-btn
          @click="$emit('updateAmount', newAmount)"
          :disabled="!valid"
          class="d-flex align-content-center"
          color="primary"
        >
          Proceed
          <v-icon small>mdi-lock</v-icon>
        </v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { helpers } from '@/mixins/helpers';

const { mapGetters: invoiceGetters } = createNamespacedHelpers('invoices');

export default {
  name: 'paymentDetails',
  props: {
    amount: {
      required: true,
    },
    balanceOperationIds: {
      required: true,
    },
    dialog: {
      required: true,
    },
  },
  mixins: [helpers],
  data() {
    return {
      valid: false,
      disabled: true,
      newAmount: 0,
      rules: [
        (value) => !!value || 'Required.',
        (v) => !Number.isNaN(Number(v)) || 'Only numbers allowed',
        (v) => Number(v) > 0 || 'Amount must be greater than 0',
        (v) =>
          Number(v) <= this.amountFormat(this.amount) ||
          `Amount must be less than or equal to ${this.priceFormat(
            this.amount
          )}`,
      ],
      flatCharges: [],
    };
  },
  watch: {
    dialog(newValue) {
      if (newValue && this.amount) {
        this.newAmount = this.amountFormat(this.amount);
        this.flatCharges = this.invoices
          .map((i) => {
            return i.charges.map((c) => ({
              id: c.id,
              patient_balance: c.patient_balance,
              procedure_description: c.procedure_description,
              procedure_code: c.procedure_code,
            }));
          })
          .flat();
      } else {
        // reset
        this.newAmount = 0;
        this.disabled = true;
        this.flatCharges = [];
      }
    },
  },
  computed: {
    ...invoiceGetters({
      invoices: 'INVOICES',
    }),
    charges() {
      const ids = Array.isArray(this.balanceOperationIds)
        ? this.balanceOperationIds
        : [this.balanceOperationIds];

      return this.flatCharges.filter((c) => {
        return ids.includes(c.id);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field {
  width: 100px !important;
}
ul {
  list-style-type: none;
  padding-left: unset;
}
</style>
