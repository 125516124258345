<template>
  <div class="Invoices">
    <v-row class="mb-2 ml-2 Invoices__row d-flex justify-space-between">
      <v-col cols="12" sm="6" md="4">
        <app-date-picker
          :range="true"
          v-model="dateRange"
          name="date"
          label="Date Range"
        />
      </v-col>
    </v-row>

    <v-row class="mb-2 ml-2 d-flex justify-space-between">
      <v-col cols="12" sm="6" md="2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined dense>
              <v-icon left>mdi-filter</v-icon>
              Is Paid {{ getStatusText }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="isPaid in isPaidStatuses" :key="isPaid">
              <v-list-item-content @click="updateList({ isPaid })">
                {{ isPaid }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined dense>
              <v-icon left>mdi-filter</v-icon>
              Filter {{ getFilterText }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="filter in filters" :key="filter">
              <v-list-item-content @click="updateList({ filter })">
                {{ filter }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-btn
          color="primary"
          dark
          @click="showCreateInvoiceModal = true"
          dense
        >
          <v-icon> mdi-plus </v-icon>
          Add Invoice
        </v-btn>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-btn color="primary" dark @click="addPaymentPlan()" dense>
          <v-icon> mdi-plus </v-icon>
          New Autopay
        </v-btn>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-btn
          color="primary"
          dark
          outlined
          @click="makePayment(unpaidInvoices, false)"
          dense
        >
          Pay total ${{
            unpaidInvoices
              ?.reduce((acc, cur) => cur.patient_balance + acc, 0)
              .toFixed(2)
          }}
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      item-key="visit_id"
      show-expand
      :headers="headers"
      :items="invoices"
      :loading="fetchInvoicesLoading"
      :server-items-length="totalInvoicesCount"
      :single-expand="true"
      :expanded.sync="expanded"
      :footer-props="{
        showFirstLastPage: false,
        showCurrentPage: true,
        prevIcon: 'mdi-arrow-collapse-left',
        nextIcon: 'mdi-arrow-collapse-right',
        itemsPerPageOptions,
      }"
      @update:options="updateList"
    >
      <template v-slot:item.createdAt="{ item }">
        <div class="Invoices__clickable">
          {{ formatDate(item.createdAt) }}
        </div>
      </template>

      <template v-slot:header.actions="{ header }">
        <div class="text-center" cols="2" align-end>
          {{ header.text.toUpperCase() }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" dense color="primary">
                Actions
              </v-btn>
            </template>
            <div class="d-flex flex-column mt-1 Invoices__menu">
              <v-btn
                outlined
                text
                color="primary"
                small
                class="pa-5"
                :disabled="isDisabledButton(item)"
                @click.prevent="makePayment(item.charges)"
              >
                Pay Invoice
              </v-btn>
              <v-btn
                outlined
                text
                color="primary"
                small
                class="pa-5"
                :disabled="isDisabledButton(item)"
                @click="updateCharge('all_paid', item)"
              >
                Mark All as Paid
              </v-btn>
              <v-btn
                outlined
                text
                color="primary"
                small
                class="pa-5"
                :disabled="isDisabledButton(item)"
                @click="showReminderModal = true"
              >
                Send Payment Link
              </v-btn>
              <v-btn
                outlined
                text
                color="primary"
                small
                class="pa-5"
                @click="copyPaymentLink"
              >
                Copy Payment Link
              </v-btn>
              <v-btn
                outlined
                text
                color="primary"
                small
                class="pa-5"
                :disabled="disableExpireButton(item)"
                @click="updateCharge('expire_invoice', item)"
              >
                Expire Invoice
              </v-btn>
            </div>
          </v-menu>
        </div>
      </template>

      <template v-slot:item.isPaid="{ item }">
        <v-chip v-if="item?.isPaid" color="green" outlined text-color="green">
          PAID
        </v-chip>
        <v-chip v-else color="red" outlined text-color="red"> UNPAID </v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip outlined>
          {{item.status}}
        </v-chip>
      </template>
      <template v-slot:item.checkbox="{ item }">
        <v-checkbox
          v-if="filter === 'MANUAL' && fetchInvoicesLoading === false"
          @change="updateSelectedInvoices(item)"
        >
        </v-checkbox>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="emptybackground">
          <div class="invoice-detail">
            <InvoiceDetails
              :key="item.visit_id"
              :invoiceId="item.visit_id"
              :charges="item.charges"
              @updateList="updateList"
              :setPaymentOptions="setPaymentOptions"
              :patientId="patientId"
            />
          </div>
        </td>
      </template>
    </v-data-table>

    <EditReminder
      @close="showReminderModal = false"
      :dialog="showReminderModal"
    />

    <CreateInvoice
      v-if="showCreateInvoiceModal"
      @close="showCreateInvoiceModal = false"
      :dialog="showCreateInvoiceModal"
      :dateRange="dateRange"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as moment from 'moment-timezone';
import AppDatePicker from '@/components/app-date-picker/app-date-picker.vue';
import InvoiceDetails from './invoice-details/invoice-details';
import EditReminder from '@/components/modals/edit-reminder/edit-reminder.vue';
import CreateInvoice from '@/components/modals/create-invoice/create-invoice.vue';

const { mapGetters: invoiceGetters, mapActions: invoiceActions } =
  createNamespacedHelpers('invoices');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapGetters: patientGetters } = createNamespacedHelpers('patient');

export default {
  name: 'InvoicesTable',
  components: {
    AppDatePicker,
    InvoiceDetails,
    EditReminder,
    CreateInvoice,
  },
  props: {
    setPaymentOptions: {
      type: Function,
      required: true,
    },
    toggleScheduleModal: {
      type: Function,
      required: true,
    },
    patientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      limit: 10,
      expanded: [],
      selectedInvoices: [],
      itemsPerPageOptions: [10, 20, 50, 100],
      isPaid: 'ALL',
      isPaidStatuses: ['PAID', 'UNPAID', 'ALL'],
      filter: 'ALL',
      filters: ['ALL', 'MANUAL', 'AUTOPAY'],
      dateRange: [
        new Date(Date.now() - 1000 * 60 * 60 * 24 * 365), // one year ago
        new Date(),
      ],
      localTZ: 'UTC',
      showReminderModal: false,
      showCreateInvoiceModal: false,
    };
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.page = 1;
      this.fetchInvoices(this.getFetchOptions);
    },
    dateRange() {
      this.updateList({});
    },
  },
  computed: {
    ...invoiceGetters({
      invoices: 'INVOICES',
      unpaidInvoices: 'UNPAID_INVOICES',
      fetchInvoicesLoading: 'FETCH_INVOICES_LOADING',
      totalInvoicesCount: 'TOTAL_INVOICES_COUNT',
    }),
    ...patientGetters({
      patient: 'PATIENT',
    }),
    ...customerGetters({
      selectedCustomer: 'SELECTED_CUSTOMER',
      customer: 'CUSTOMER',
    }),
    headers() {
      const defaults = { sortable: false, filterable: false };
      return [
        {
          text: 'Invoice No.',
          value: 'visit_id',
          ...defaults,
        },
        {
          text: 'Total charge (USD)',
          value: 'total_charge',
          ...defaults,
        },
        {
          text: 'Remaining balance (USD)',
          value: 'patient_balance',
          ...defaults,
        },
        {
          text: 'Is Paid',
          value: 'isPaid',
          ...defaults,
        },
        {
          text: 'Status',
          value: 'status',
          ...defaults,
        },
        {
          text: 'Date Created',
          value: 'createdAt',
          ...defaults,
        },
        {
          text: 'Actions',
          value: 'actions',
          ...defaults,
        },
        {
          text: '',
          value: 'checkbox',
          ...defaults,
        },
      ];
    },
    getStatusText() {
      return `= ${this.isPaid}`;
    },
    getFilterText() {
      return `= ${this.filter}`;
    },
    getFetchOptions() {
      const option = {
        page: this.page,
        limit: this.itemsPerPage,
        patientId: this.patientId,
        fromDate: new Date(
          this.dateRange[0].setHours(23, 59, 59, 999)
        ).toISOString(),
        toDate: new Date(
          this.dateRange[1].setHours(23, 59, 59, 999)
        ).toISOString(),
        expand: 'charges,all_unpaid',
      };

      if (this.isPaid !== 'ALL') {
        option.isPaid = this.isPaid === 'PAID' ? true : false;
      }

      if (this.filter !== 'ALL') {
        option.filter = this.filter.toLowerCase();
      }
      return option;
    },
  },
  created() {
    this.page = 1;
    this.itemsPerPage = 10;
    this.selectedInvoices = [];
  },
  methods: {
    ...invoiceActions(['fetchInvoices', 'chargeActions', 'sendReminder']),
    isDisabledButton(invoice) {
      if (!invoice?.charges) {
        return true;
      }
      if (invoice.charges.some((ch) => ch.statusIsPaid === true)) {
        return true;
      }
      if (invoice.charges.every((ch) => ch.status !== 'ACTIVE')) {
        return true;
      }
      let expired = false;
      invoice.charges.forEach((charge) => {
        if (
          charge.invoice_type === 'prePay' &&
          new Date(charge.date_of_service) < new Date()
        ) {
          expired = true;
        }
      });
      return expired;
    },
    disableExpireButton(invoice) {
      if (!invoice?.charges) {
        return true;
      }
      if (invoice.charges.some((ch) => ch.status === 'PAID')) {
        return true;
      }
      return false
    },
    updateCharge(action, invoice) {
      this.chargeActions({
        action,
        visit_id: invoice.visit_id,
        patient_id: this.patient.patient_id,
      });
      this.updateList({});
    },
    updateSelectedInvoices(item) {
      const index = this.selectedInvoices.findIndex(
        (i) => i.visit_id === item.visit_id
      );
      if (index > -1) {
        this.selectedInvoices.splice(index, 1);
      } else {
        this.selectedInvoices.push(item);
      }
    },
    formatDate(date) {
      if (!date) return '';
      else return moment(date).tz(this.localTZ).format('YYYY-MM-DD');
    },
    makePayment(balanceOperations, showDetails = true) {
      const balance = balanceOperations.reduce(
        (a, b) => a + b.patient_balance,
        0
      );
      const charges = balanceOperations.map((c) => c.charge_id).join(',');
      const balanceOperationIds = balanceOperations.map((c) => c.id);
      this.setPaymentOptions({
        balance,
        charges,
        balanceOperationIds,
        showDetails,
      });
    },
    addPaymentPlan() {
      this.toggleScheduleModal();
    },
    updateList({ itemsPerPage, page, isPaid, filter }) {
      this.selectedInvoices = [];

      if (itemsPerPage) this.itemsPerPage = itemsPerPage;
      if (page) this.page = page;
      if (isPaid) this.isPaid = isPaid;
      if (filter) {
        if (filter === 'MANUAL') {
          this.isPaid = 'UNPAID';
        }
        this.filter = filter;
      }

      this.fetchInvoices(this.getFetchOptions);
    },
    copyPaymentLink() {
      const host = process.env.VUE_APP_WEB_APP_URL;
      const short_code = this.patient?.short_code;
      const link = `${host}/?code=${short_code}&type=billing`;

      navigator.clipboard.writeText(link);
      this.$store.dispatch(
        'notifications/pushNotification',
        'Payment link copied',
        { root: true }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.Invoices {
  padding-bottom: 5px;
  &__row {
    width: 100%;
  }
  &__filter-row {
    padding: 10px 0;
  }

  &__clickable {
    cursor: pointer;
  }

  &__menu {
    background-color: white;
  }
}

.emptybackground {
  background-color: white;
}

.invoice-detail {
  padding: 15px;
}

.fixed {
  position: sticky;
  width: 5em;
  left: 0;
  top: auto;
  z-index: 1;
}
</style>
