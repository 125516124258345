<template>
  <div>
    <v-data-table
      item-key="id"
      class="Plans__clickable"
      show-expand
      :headers="headers"
      :items="messages"
      :loading="isLoading"
      :server-items-length="messages.length"
      :footer-props="{
        showFirstLastPage: false,
        showCurrentPage: true,
        prevIcon: 'mdi-arrow-collapse-left',
        nextIcon: 'mdi-arrow-collapse-right',
        itemsPerPageOptions,
      }">
      <template v-slot:item.createdAt="{ item }">
        <div>
          {{ formatDate(item.createdAt) }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as moment from 'moment';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
export default {
  name: 'MessagesTable',
  components: {},
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      messagesCurrentPage: 1,
      messagesCurrentItemsPerPage: 50,
      dialog: false,
      status: 'Active',
      statuses: ['Active', 'Disabled', 'Paid', 'Cancelled'],
      itemsPerPageOptions: [10, 20, 50, 100],
      localTZ: 'UTC',
      isLoading: false,
      messages: [],
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    headers() {
      const defaults = { sortable: false, filterable: false };
      return [
        {
          text: `Date (${this.localTZ})`,
          value: 'createdAt',
          ...defaults,
        },
        {
          text: 'To',
          value: 'to',
          ...defaults,
        },
        {
          text: 'Message',
          value: 'body',
          ...defaults,
        },
      ];
    },
  },
  mounted() {
    this.setLocalTimezone();
    this.getPatientMessages();
  },
  methods: {
    setLocalTimezone() {
      this.localTZ = moment.tz.guess();
    },
    onPlanPageChange(options) {
      this.paymentPlanCurrentPage = options.page;
      this.paymentPlanCurrentItemsPerPage = options.itemsPerPage;
      this.getPaymentPlans();
    },
    updatePaymentPlan(selectedPlan) {
      this.toggleScheduleModal({ selectedPaymentPlan: selectedPlan });
    },
    updateStatus(status) {
      this.status = status;
      this.getPaymentPlans();
    },
    formatDate(date) {
      if (!date) return '';
      return moment(date).tz(this.localTZ).format('MMMM Do YYYY');
    },
    getPatientMessages() {
      this.isLoading = true;
      const customerID =
        this.customer.customer_id === 0
          ? this.selectedCustomer.customer_id
          : this.customer.customer_id;
      const URL = '/proxy/get-patient-messages';
      return window.axios
        .post(URL, {
          customer_id: customerID,
          pid: this.patientId,
        })
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.messages = response.data.output.messages;
          } else throw new Error(`Messages response invalid`);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async refreshPage() {
      await this.getPaymentPlans();
    },
    async toggleBatch(batchId) {
      await this.toggleBatchStatus(batchId);
      await this.getPaymentPlans();
    },
  },
};
</script>
<style scoped lang="scss">
.Plans {
  padding-top: 20px;

  &__filter-row {
    padding: 15px 0;
  }

  &__clickable {
    cursor: pointer;
  }
}
.emptybackground {
  background-color: white;
}
</style>
