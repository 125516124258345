<template>
  <div class="my-10">
    <v-card class="pr-5 mb-5 pb-3">
      <v-card-title class="d-flex">
        <h5>Invoice No. {{ invoice_id }} Activities</h5>
      </v-card-title>

      <v-progress-circular
        :size="50"
        :width="3"
        color="primary"
        v-if="fetchInvoiceActivityLoading"
        class="ml-5"
        indeterminate />

      <v-timeline
        v-else
        dense
        :align-top="true">
        <v-timeline-item
          v-for="(activity, index) in activities"
          :key="`transaction_${index}`"
          small>
          <v-card
            class="ma-1 pl-3"
            v-if="activity.type === 'invoice'">
            <v-row>
              <v-col cols="3">
                <span class="body-2 font-weight-bold">
                  {{ formatDate(activity.invoice.createdAt) }}
                </span>
              </v-col>
              <v-col>
                <div class="d-flex justify-content-between">
                  <v-card-text class="py-0 font-weight-bold">
                    Invoice Created
                  </v-card-text>
                  <v-card-text class="py-0 text-right font-weight-bold">
                    Invoice Type:
                    {{ activity.invoice.invoice_type ?? 'postPay' }}
                  </v-card-text>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <v-card
            class="ma-1 pl-3"
            v-if="activity.type === 'transaction'">
            <v-row>
              <v-col cols="3">
                <span class="body-2 font-weight-bold">
                  {{ formatDate(activity?.date) }}
                </span>
              </v-col>
              <v-col>
                <div class="d-flex justify-content-between">
                  <v-card-text class="py-0 font-weight-bold">
                    Transaction {{ activity?.transaction?.autopay_batch_id ? 'Autopay' : '' }}
                  </v-card-text>
                  <v-card-text class="py-0 font-weight-bold">
                    STATUS: {{ activity?.transaction?.status }}
                  </v-card-text>
                  <v-card-text class="py-0 text-right font-weight-bold">
                    Amount: ${{ activity?.transaction?.amount }}
                  </v-card-text>
                </div>
                <v-card-text>
                  {{
                    activity?.transaction?.message
                      ? `Message: ${activity?.transaction?.message}`
                      : null
                  }}
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as moment from 'moment-timezone';

const { mapGetters: invoiceGetters, mapActions: invoiceActions } =
  createNamespacedHelpers('invoices');

export default {
  name: 'InvoiceActivities',
  props: {
    patient_id: {
      type: String,
      required: true,
    },
    pid: {
      type: Number,
      required: true,
    },
    invoice_id: {
      required: true,
    },
  },
  computed: {
    ...invoiceGetters({
      activities: 'INVOICE_ACTIVITIES',
      fetchInvoiceActivityLoading: 'FETCH_ACTIVITIES_LOADING',
    }),
  },
  mounted() {
    this.getInvoiceActivity({ invoice_id: this.invoice_id, pid: this.pid });
  },
  methods: {
    ...invoiceActions(['getInvoiceActivity']),

    formatDate(date) {
      if (!date) return '';
      else return moment(date).tz('UTC').format('YYYY-MM-DD HH:mm');
    },
  },
};
</script>
